<template>
  <div class="row">
    <div class="flex xs12">
      <data-form
        :form="form"
        :form-title="$t('forms.new')"
        :loading="loading"
        @submit="submit"
      >
        <actions
          slot="card-actions"
          crud-links="forms"
          controller="DynamicForms"
          :actions="actions"
        />
      </data-form>
    </div>
  </div>
</template>

<script>
const Actions = () => import(/* webpackPrefetch: true */ '@/components/extras/Actions')
const DataForm = () => import(/* webpackPrefetch: true */ './Form')

export default {
  name: 'forms-new',
  components: {
    DataForm,
    Actions,
  },
  data () {
    return {
      loading: false,
      actions: ['index'],
      form: {
        id: 0,
      },
    }
  },
  methods: {
    // FIXME
    routeBuilder () {
      return 'forms/'
    },
    async submit (form) {
      this.loading = true

      try {
        await this.$http.post(this.routeBuilder(), form)
      } catch (error) {
        // console.log('Error updating data', error)
        this.loading = false
        return
      }

      this.loading = false
      this.showToast(this.$t('notifications.alerts.success'), {
        icon: 'fa-check',
        position: 'top-right',
      })
      this.$router.push({ name: 'formsIndex' })
    },
  },
}
</script>
